<template>
  <div class="banner" :style="{ backgroundImage: `url(${backgroundImage})` }">
    <div class="icon" v-if="iconImg">
      <img :src="iconImg" width="70px" height="70px" alt="">
    </div>
    <div class="above">{{ title }}</div>
    <div class="desc">
      <div class="one" v-html="desc.one"></div>
      <div class="two" v-if="desc.two" v-html="desc.two"></div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        backgroundImage: {
          type: String
        },
        iconImg: {
            type: String,
        },
        title: {
            type: String
        },
        desc: {
            type: Object,
        }
    },

};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 398px;
  padding: 0 224px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-size: cover;

  .above {
    font-size: 26px;
    color: #fff;

    margin-top: 10px;
  }
  .desc {
    width: 900px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    line-height: 22px;
    margin-top: 20px;
  }
}
</style>
